import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ lang, meta, siteTitle }) {
  const data = useStaticQuery(
    graphql`
      query Settings {
        settings: allSanitySettings {
          edges {
            node {
              title
              description
              favicon {
                asset {
                  url
                }
              }
              image {
                asset {
                  url
                }
              }
            }
          }
        }
      }
    `
  );

  const settings = data.settings.edges.map(edge => edge.node);
  const { title, description } = settings[0];
  const preview = settings[0].image?.asset.url;
  const favicon = settings[0].favicon?.asset.url;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`${title} ${siteTitle ? `• ${siteTitle}` : ''}`}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:image`,
          content: preview,
        },
        {
          property: `og:type`,
          content: `website`,
        },
      ].concat(meta)}
      link={[{ rel: 'icon', type: 'image/png', href: `${favicon}` }]}
    />
  );
}

SEO.defaultProps = {
  lang: `de`,
  meta: [],
  siteTitle: ``,
};

SEO.propTypes = {
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  siteTitle: PropTypes.string,
};

export default SEO;
