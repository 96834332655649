import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useEventListener, useClientSide } from '../hooks/index';

const Cursor = () => {
  const client = useClientSide();

  useEffect(() => {
    if (client) {
      // eslint-disable-next-line global-require
      const mojs = require('@mojs/core');
      class Stern extends mojs.CustomShape {
        // eslint-disable-next-line class-methods-use-this
        getShape() {
          return '<path d="M45.68,60.4L17.76,88.33l-6.09-6.09L39.6,54.31H0v-8.62h39.6L11.67,17.77l6.09-6.09L45.68,39.6V0h8.63v39.6l27.91-27.91 l6.09,6.09L60.4,45.69H100v8.62H60.4l27.91,27.93l-6.09,6.09L54.31,60.4V100h-8.63V60.4z"/>';
        }
      }
      mojs.addShape('stern', Stern);

      const CIRCLE_RADIUS = 20;
      const RADIUS = 0;
      const circle = new mojs.Shape({
        className: 'mo-shape',
        left: 0,
        top: 0,
        stroke: { black: 'black' },
        strokeWidth: { [1 * CIRCLE_RADIUS]: 0 },
        fill: 'none',
        scale: { 0: 1 },
        radius: CIRCLE_RADIUS,
        duration: 400,
        easing: 'cubic.out',
      });

      const burst = new mojs.Burst({
        className: 'mo-shape',
        left: 0,
        top: 0,
        radius: { 4: RADIUS },
        angle: 45,
        count: 14,
        timeline: { delay: 300 },
        children: {
          radius: 2.5,
          fill: ['black'],
          scale: { 1: 0, easing: 'quad.in' },
          pathScale: [0.8, null],
          degreeShift: [13, null],
          duration: [100, 900],
          easing: 'quint.out',
        },
      });

      const stern = new mojs.Shape({
        className: 'mo-shape',
        left: 0,
        top: 2,
        shape: 'stern',
        fill: 'black',
        scale: { 0: 1 },
        easing: 'elastic.out',
        duration: 1000,
        delay: 300,
        radius: 11,
      });

      const handleClick = () => {
        document.addEventListener('click', function(e) {
          const coords = { x: e.pageX, y: e.pageY };
          burst.tune(coords).replay();
          circle.tune(coords).replay();

          stern.tune(coords).replay();
        });
      };

      document.addEventListener('click', handleClick);
      return () => document.removeEventListener('click', handleClick);
    }
  });

  const cursorRef = React.useRef();

  const [hover, setHover] = useState(false);

  if (client) {
    const links = document.querySelectorAll('a');
    const buttons = document.querySelectorAll('button');
    links.forEach(link => {
      link.addEventListener('mouseover', () => setHover(true));
      link.addEventListener('mouseleave', () => setHover(false));
    });
    buttons.forEach(button => {
      button.addEventListener('mouseover', () => setHover(true));
      button.addEventListener('mouseleave', () => setHover(false));
    });
  }

  const handleCursor = e => {
    cursorRef.current.style.left = `${e.pageX}px`;
    cursorRef.current.style.top = `${e.pageY}px`;
  };

  useEventListener('mousemove', handleCursor);

  return (
    <StyledCursor
      className={`cursor ${hover ? 'cursor-hover' : ''}`}
      ref={cursorRef}
    ></StyledCursor>
  );
};

const StyledCursor = styled.span`
  top: -50px;
  pointer-events: none;
`;

export default Cursor;
