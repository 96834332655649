import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { useCheckEN } from '../hooks/index';

import Arrow from '../static/assets/arrow.svg';

const Nav = ({ onClickUp, onClickDown, disableUp, disableDown }) => {
  const data = useStaticQuery(graphql`
    query Nav {
      nav: allSanityNav {
        edges {
          node {
            first
            fifth
            fourth
            sixth
            third
            second
          }
        }
      }
    }
  `);

  const nav = data.nav.edges.map(edge => edge.node);

  const [url] = useCheckEN();

  return (
    <StyledNav>
      <button
        type="button"
        className={`${disableUp ? 'disable' : ''} arrow-up`}
        onClick={onClickUp}
      >
        <Arrow />
        <Arrow />
        <Arrow />
        <Arrow />
        <Arrow />
      </button>
      <ul>
        <li>
          <Link to={!url ? '/studio' : '/en/studio'}>{nav[0].first}</Link>
          <span>—</span>
        </li>
        <li>
          <Link to={!url ? '/strategie' : '/en/strategie'}>
            {nav[0].second}
          </Link>
          <span>—</span>
        </li>
        <li>
          <Link to={!url ? '/design' : '/en/design'}>{nav[0].third}</Link>
          <span>—</span>
        </li>
        <li>
          <Link to={!url ? '/jobs' : '/en/jobs'}>{nav[0].fourth}</Link>
          <span>—</span>
        </li>
        <li>
          <Link to={!url ? '/blog' : '/en/blog'}>{nav[0].fifth}</Link>
          <span>—</span>
        </li>
        <li>
          <Link to={!url ? '/contact' : '/en/contact'}>{nav[0].sixth}</Link>
        </li>
      </ul>
      <button
        type="button"
        className={`${disableDown ? 'disable' : ''} arrow-down`}
        onClick={onClickDown}
      >
        <Arrow />
        <Arrow />
        <Arrow />
        <Arrow />
        <Arrow />
      </button>
    </StyledNav>
  );
};

const StyledNav = styled.nav`
  z-index: 2;
  width: 100%;
  position: fixed;
  bottom: 0;
  height: var(--spacing-L);
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: var(--spacing-XS);
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.85) 0%,
    rgba(255, 255, 255, 0.5) 40%,
    rgba(255, 255, 255, 0) 100%
  );

  .arrow-up {
    display: flex;
    transform: rotate(180deg);
    width: var(--spacing-M);
    &.disable {
      cursor: default;
      svg {
        polygon {
          fill: var(--text-color);
          opacity: 25%;
        }
      }
    }
  }

  .arrow-down {
    display: flex;
    width: var(--spacing-M);
    &.disable {
      cursor: default;
      svg {
        polygon {
          fill: var(--text-color);
          opacity: 25%;
        }
      }
    }
  }

  ul {
    display: flex;
  }

  span {
    padding: 0 calc(var(--spacing-XXS) / 3);
  }

  @media (max-width: 1020px) {
    padding: var(--spacing-XXS);
    ul {
      display: none;
    }
  }
`;

Nav.propTypes = {
  onClickUp: PropTypes.func,
  onClickDown: PropTypes.func,
  disableUp: PropTypes.bool,
  disableDown: PropTypes.bool,
};

export default Nav;
