import { useEffect, useState } from 'react';

export const useCheckEN = () => {
  const [url, setUrl] = useState();

  useEffect(() => {
    const path = window.location.toString().includes('/en');
    setUrl(path);
  }, []);

  return [url];
};
