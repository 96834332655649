import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { injectGlobal } from 'emotion';

import '../styles/app.css';
import Header from './header';
import RichText from './richtext';
import Nav from './nav';
import Cursor from './cursor';
import NavMobile from './navmobile';
import { useCheckEN, useMobile } from '../hooks/index';

const Layout = ({
  hideHeader,
  hideNav,
  subtitle,
  current,
  children,
  enPath,
  dePath,
  disableUp,
  disableDown,
  onClickUp,
  onClickDown,
}) => {
  const data = useStaticQuery(graphql`
    query Layout {
      settings: allSanitySettings {
        edges {
          node {
            textColor {
              hex
            }
            bgColor {
              hex
            }
          }
        }
      }
      header: allSanityHeader {
        edges {
          node {
            title
            socialMedia {
              behance
              behanceLink
              facebook
              facebookLink
              instagram
              instagramLink
              twitter
              twitterLink
            }
          }
        }
      }
    }
  `);

  const colors = data.settings.edges.map(edge => edge.node);
  const textColor = colors[0].textColor?.hex;
  const bgColor = colors[0].bgColor?.hex;

  injectGlobal`
  :root {
    --text-color: ${textColor};
    --bg-color: ${bgColor};
  }
  `;
  const header = data.header.edges.map(edge => edge.node);

  const [showMenu, setShowMenu] = React.useState(false);
  const handleMenu = () => {
    setShowMenu(!showMenu);
  };

  const mobile = useMobile();
  const [url] = useCheckEN();

  return (
    <>
      {!mobile && <Cursor />}
      <Header dePath={dePath} enPath={enPath}>
        <div>
          <Link to={url ? '/en' : '/'}>
            <StyledBottomSpacing className="spacing">
              {header[0].title}
            </StyledBottomSpacing>
          </Link>
          {!hideHeader && (
            <h1>
              <RichText blocks={subtitle} />
            </h1>
          )}
        </div>
        <div className="right-aligned">
          <StyledCurrent className={!hideHeader ? 'spacing' : ''}>
            {current}
          </StyledCurrent>
          {!hideHeader && (
            <StyledSocial>
              <a
                href={header[0].socialMedia.instagramLink}
                target="_blank"
                rel="noreferrer"
              >
                <p>{header[0].socialMedia.instagram}</p>
              </a>
              <span>|</span>
              <a
                href={header[0].socialMedia.facebookLink}
                target="_blank"
                rel="noreferrer"
              >
                <p>{header[0].socialMedia.facebook}</p>
              </a>
              <span>|</span>
              <a
                href={header[0].socialMedia.behanceLink}
                target="_blank"
                rel="noreferrer"
              >
                <p>{header[0].socialMedia.behance}</p>
              </a>
              <span>|</span>
              <a
                href={header[0].socialMedia.twitterLink}
                target="_blank"
                rel="noreferrer"
              >
                <p>{header[0].socialMedia.twitter}</p>
              </a>
            </StyledSocial>
          )}
          <>
            <Link to={dePath}>DE</Link>
            <span style={{ padding: '0 calc(var(--spacing-XXS) / 1.5)' }}>
              *
            </span>
            <Link to={enPath}>EN</Link>
          </>
          <StyledMenu onClick={handleMenu}>MENU</StyledMenu>
        </div>
      </Header>
      {showMenu && <NavMobile onClick={() => setShowMenu(!showMenu)} />}
      {!hideNav && (
        <Nav
          disableUp={disableUp}
          disableDown={disableDown}
          onClickDown={onClickDown}
          onClickUp={onClickUp}
        />
      )}
      <main>{children}</main>
    </>
  );
};

const StyledBottomSpacing = styled.p`
  &.spacing {
    padding-bottom: var(--spacing-XXS);
  }
`;

const StyledCurrent = styled.p`
  mix-blend-mode: difference;

  svg {
    transform: rotate(315deg);
  }

  &.spacing {
    padding-bottom: var(--spacing-XXS);
  }

  @media (max-width: 1020px) {
    display: none;
  }
`;

const StyledMenu = styled.p`
  display: none;

  @media (max-width: 1020px) {
    text-align: right;
    display: block;
    padding: var(--spacing-XXS) 0 var(--spacing-XXS) var(--spacing-XXS);
  }
`;

const StyledSocial = styled.div`
  display: flex;

  span {
    padding: 0 calc(var(--spacing-XXS) / 1.5);
  }

  @media (max-width: 1020px) {
    display: none;
  }
`;

Layout.propTypes = {
  hideHeader: PropTypes.bool,
  hideNav: PropTypes.bool,
  subtitle: PropTypes.array,
  current: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.node.isRequired,
  enPath: PropTypes.string,
  dePath: PropTypes.string,
  disableUp: PropTypes.bool,
  disableDown: PropTypes.bool,
  onClickUp: PropTypes.func,
  onClickDown: PropTypes.func,
};

export default Layout;
