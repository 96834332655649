import { useEffect, useState } from 'react';
import { useClientSide } from './index';

export const useMobile = () => {
  const client = useClientSide();
  const [isMobile, setMobile] = useState(false);

  useEffect(() => {
    const userAgent =
      typeof window.navigator === 'undefined' ? '' : navigator.userAgent;
    const mobile = Boolean(userAgent.match(/Android|iPhone|iPad/i));
    setMobile(client && mobile);
  }, [client]);

  return isMobile;
};
