import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { useClientSide, useCheckEN } from '../hooks/index';

const NavMobile = ({ onClick }) => {
  const data = useStaticQuery(graphql`
    query MobileNav {
      nav: allSanityNav {
        edges {
          node {
            first
            fifth
            fourth
            sixth
            third
            second
          }
        }
      }
      header: allSanityHeader {
        edges {
          node {
            title
            socialMedia {
              behance
              behanceLink
              facebook
              facebookLink
              instagram
              instagramLink
              twitter
              twitterLink
            }
          }
        }
      }
    }
  `);

  const header = data.header.edges.map(edge => edge.node);
  const nav = data.nav.edges.map(edge => edge.node);

  const client = useClientSide();
  const [url] = useCheckEN();

  const [height, setHeight] = React.useState();
  useEffect(() => {
    setHeight(client && window.innerHeight);
  }, [client]);

  return (
    <StyledNav style={{ height }}>
      <button type="button" className="close big" onClick={onClick}>
        X
      </button>
      <ul className="centered big">
        <li>
          <Link to={!url ? '/studio' : '/en/studio'}>{nav[0].first}</Link>
        </li>
        <li>
          <Link to={!url ? '/strategie' : '/en/strategie'}>
            {nav[0].second}
          </Link>
        </li>
        <li>
          <Link to={!url ? '/design' : '/en/design'}>{nav[0].third}</Link>
        </li>
        <li>
          <Link to={!url ? '/jobs' : '/en/jobs'}>{nav[0].fourth}</Link>
        </li>
        <li>
          <Link to={!url ? '/blog' : '/en/blog'}>{nav[0].fifth}</Link>
        </li>
        <li>
          <Link to={!url ? '/contact' : '/en/contact'}>{nav[0].sixth}</Link>
        </li>
        <li>
          <a
            href={header[0].socialMedia.instagramLink}
            target="_blank"
            rel="noreferrer"
          >
            <span>{header[0].socialMedia.instagram}</span>
          </a>
          <span style={{ padding: '0 var(--spacing-XXS)' }}>|</span>
          <a
            href={header[0].socialMedia.facebookLink}
            target="_blank"
            rel="noreferrer"
          >
            <span>{header[0].socialMedia.facebook}</span>
          </a>
          <br />
          <a
            href={header[0].socialMedia.behanceLink}
            target="_blank"
            rel="noreferrer"
          >
            <span>{header[0].socialMedia.behance}</span>
          </a>
          <span style={{ padding: '0 var(--spacing-XXS)' }}>|</span>
          <a
            href={header[0].socialMedia.twitterLink}
            target="_blank"
            rel="noreferrer"
          >
            <span>{header[0].socialMedia.twitter}</span>
          </a>
        </li>
      </ul>
    </StyledNav>
  );
};

const StyledNav = styled.nav`
  z-index: 10;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  padding: var(--spacing-XS);
  background: var(--text-color);
  color: var(--bg-color);
  padding: var(--spacing-XXS);
  display: flex;
  align-items: center;

  .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: var(--spacing-XXS) var(--spacing-XXS) var(--spacing-S)
      var(--spacing-S);
  }

  li {
    padding-bottom: var(--spacing-XS);
    text-decoration: underline;
  }
`;

NavMobile.propTypes = {
  onClick: PropTypes.func,
};

export default NavMobile;
