import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Header = ({ children }) => <StyledHeader>{children}</StyledHeader>;

const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  top: 0;
  padding: var(--spacing-S) var(--spacing-XS);
  z-index: 3;
  color: var(--bg-color);
  mix-blend-mode: difference;
  line-height: var(--line-height-3);

  svg {
    fill: var(--bg-color);
  }

  @media (max-width: 1020px) {
    padding: var(--spacing-XXS);
  }
`;

Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
